// i18next-extract-mark-ns-start terms_free_tours
import * as React from "react"
import { graphql } from "gatsby"
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageSection from "../../components/page-section"

const TermsOfServicePageVistulaDayTours = () => {

  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t("Regulamin rejsów i atrakcji bezpłatnych")} />
      <PageSection>
        <h1 className="pb-3"><Trans>Regulamin rejsów i atrakcji bezpłatnych w ramach wydarzenia Święto Wisły 2023</Trans></h1>
      </PageSection>
      <PageSection className="bg-light text-black">
        <h3><Trans>Regulamin bezpłatnych rejsów i atrakcji Święta Wisły 2023</Trans>.</h3>
        <h5 className="my-5 fw-bold">§1. <Trans>Organizator Święta Wisły 2023</Trans></h5>
        <p>1. <Trans>Organizatorem wydarzenia jest Miasto st. Warszawa, realizatorem i koordynatorem jest Rosa Venti Sp. z o. o. ul. Nowy Świat 33 / 13, 00-029 Warszawa NIP: 525-281-76-48 na zlecenie Zarządu Zieleni w Warszawie</Trans>.</p>
        <p>2. <Trans>Projekt finansowany jest z budżetu Miasta St. Warszawy</Trans>.</p>
        <p>3. <Trans>Organizator współpracuje przy realizacji projektu z armatorami wynajmującymi jednostki, posiadające odpowiednie aktualne dokumenty, oraz wykorzystuje własne jednostki</Trans>.</p>
        <h5 className="my-5 fw-bold">§2. <Trans>Rejs</Trans></h5>
        <p>1. <Trans>Za rejs rozumiana jest wycieczka po Wiśle różnymi jednostkami przystosowanymi do żeglugi pasażerskiej po Wiśle</Trans>.</p>
        <p>2. <Trans>Rejsy i rozpoczyna się w 5 lokalizacjach: Dworzec Wodny Warszawa, Płyta Desantu, Plaża Rusałka (Zoo), wyspa Poniatówka, Plaża Poniatówka</Trans>.</p>
        <p>3. <Trans>Harmonogram godzinowy i lokalizacje miejsc rozpoczęcia spływów znajduje się na stronie <Link to="/swieto-wisly-2023/atrakcje">rejsy.waw.pl/swieto-wisly-2023/atrakcje</Link></Trans></p>
        <p>4. <Trans>Rejsy odbywają się w dniu 2 września 2023.</Trans>.</p>
        <h5 className="my-5 fw-bold">§3. <Trans>Rezerwacje</Trans></h5>
        <p>1. <Trans>Rezerwacje odbywają się tylko i wyłącznie na stronie <a href="/">rejsy.waw.pl</a></Trans></p>
        <p>2. <Trans>Rezerwacji może dokonać osoba pełnoletnia dla maksymalnie 4 osób na jeden rejs. Za każdą osobę pobierana jest kaucja zwrotna w wysokości 20 zł</Trans>.</p>
        <p>3. <Trans>Dzieci mogą brać udział w rejsach wyłącznie pod opieką rodziców/opiekunów</Trans></p>
        <p>4. <Trans>Informacja o uruchomieniu zapisów będzie zamieszczana na portalach społecznościowych Dzielnicy Wisła oraz stronie wydarzenia na portalu facebook</Trans>.</p>
        <p>5. <Trans>Warunkiem uczestnictwa w rejsie jest odebranie biletu w miejscu rozpoczęcia rejsu, najpóźniej 10 minut przez godziną rozpoczęcia rejsu.</Trans>.</p>
        <h5 className="my-5 fw-bold">§4. <Trans>Odwołanie, zmiany rezerwacji</Trans></h5>
        <p>1. <Trans>Odwołanie oraz zmiana rezerwacji odbywa się poprzez maila: <a href="mailto:kontakt@rejsy.waw.pl">kontakt@rejsy.waw.pl</a></Trans></p>
        <p>2. <Trans>Rejsy po stronie organizatora odwoływane są jedynie w przypadku warunkach, przy których żegluga mogłaby być niebezpieczna: wysoki stan wód, wiatr, alerty RCB</Trans>.</p>
        <p>3. <Trans>W przypadku odwołania rejsu w wyniki sił wyższych, ustalany jest z uczestnikami nowy termin rezerwacji, bądź zwracana kaucja</Trans>.</p>
        <p>4. <Trans><strong>Pogoda nie jest powodem do odwoływania rezerwacji</strong>. W deszczowe, zimne dni możliwa jest zmiana jednostki przez organizatora na taką, która posiada zadaszenie / ogrzewanie</Trans>.</p>
        <h5 className="my-5 fw-bold">§5. <Trans>Kaucja</Trans></h5>
        <p>1. <Trans>Przy dokonywaniu rezerwacji pobierana zostaje kaucja zwrotna w wysokości 20 zł / os. Kaucję należy wpłacić przy użyciu systemu rezerwacji <a target="_blank" href="https://tpay.com" >Tpay.com</a></Trans>.</p>
        <p>2. <Trans>Zwrot kaucji następuje automatycznie poprzez system rezerwacji dzień po rejsie. Transfer środków w zależności od banku może potrwać <strong>do 3 dni roboczych</strong>. W większości przypadków zwrot środków odbywa się natychmiastowo</Trans>.</p>
        <p>3. <Trans>Kaucja <strong>nie jest zwracana</strong>, jeśli uczestnik nie pojawił się na rejsie, bądź odwołał swoją rezerwację <strong>na mniej niż 24h</strong> przed planowaną rezerwacją</Trans>.</p>
        <h5 className="my-5 fw-bold">§6. <Trans>Pytania, uwagi</Trans></h5>
        <p>1. <Trans>Wszystkie pytania, uwagi prosimy kierować na adres mailowy: <a href="mailto:kontakt@rejsy.waw.pl">kontakt@rejsy.waw.pl</a></Trans></p>
      </PageSection>
    </Layout >
  )
}

export default TermsOfServicePageVistulaDayTours

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: [ "navigation", "footbar"]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;